<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-container
      fluid
    >
      <b-row>

        <b-col
          cols="12"
          class="px-1"
        >
          <b-form-group
            label="Search"
            label-for="filterDriverSearch"
            label-class="font-weight-bolder"
            class="mb-2"
          >
            <b-input
              id="filterDriverSearch"
              v-model="tableDrivers.filter.search"
              placeholder="search here"
              autocomplete="off"
              debounce="1000"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="px-1 mb-2"
        >
          <b-button
            size="sm"
            type="button"
            variant="success"
            @click.prevent="createDriver"
          >
            Add Driver
          </b-button>
        </b-col>

      </b-row>

      <b-row
        class="mt-1"
      >
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableDrivers"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :items="tableProvider"
            :busy="tableDrivers.busy"
            :filter="tableDrivers.filter"
            :fields="tableDrivers.fields"
            :stacked="tableDrivers.stacked"
            :sort-by.sync="tableDrivers.sortBy"
            :sort-desc.sync="tableDrivers.sortDesc"
            :sort-direction="tableDrivers.sortDirection"
            :filter-included-fields="tableDrivers.filterOn"
            :current-page="tableDrivers.currentPage"
            :per-page="tableDrivers.perPage"
          >
            <template #cell(index)="{ index }">
              {{ tableRowNumber(tableDrivers, index) }}
            </template>

            <template #cell()="{ value }">
              <div class="text-md-nowrap">
                {{ value }}
              </div>
            </template>

            <template #cell(action)="{ item }">
              <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                <b-button
                  size="sm"
                  variant="success"
                  @click.prevent="editDriver(item)"
                >
                  Edit
                </b-button>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableDrivers.perPage"
            :options="tableDrivers.pageOptions"
            :disabled="tableDrivers.busy"
            class="w-100 w-md-25"
            size="sm"
          />
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableDrivers.currentPage"
            :total-rows="tableDrivers.totalRows"
            :per-page="tableDrivers.perPage"
            :disabled="tableDrivers.busy"
            aria-controls="table"
            prev-text="Prev"
            next-text="Next"
            first-number
            last-number
            pills
          />
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modalDriver"
      scrollable
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      :title="ModalDriverTitle"
    >
      <ValidationObserver
        ref="formDriver"
      >
        <form
          role="form"
          novalidateDriver
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="driver name"
                vid="driver_name"
                rules="required|max:100"
              >
                <b-form-group
                  label="Driver Name"
                  label-for="driver_name"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="driver_name"
                    v-model="driver.driver_name"
                    type="text"
                    autocomplete="off"
                    maxlength="100"
                    placeholder="enter driver name here"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="mobile number"
                vid="mobile_number"
                rules="required|max:11"
              >
                <b-form-group
                  label="Mobile Number"
                  label-for="mobile_number"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="mobile_number"
                    v-model="driver.mobile_number"
                    type="text"
                    autocomplete="off"
                    maxlength="11"
                    placeholder="enter mobile number here"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              v-if="state.editing"
              cols="12"
            >
              <b-form-group>
                <b-form-checkbox
                  id="is_active"
                  v-model="driver.active"
                  switch
                  value="1"
                  name="is_active"
                  unchecked-value="0"
                  :disabled="state.busy"
                >
                  Is Active?
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

        </form>
      </ValidationObserver>

      <template #modal-footer="{cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click.prevent="validateDriver"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click.prevent="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import QUERY from 'lodash'
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { core } from '@/config/pluginInit'
import { SLODriver } from '@/services'

export default {
  name: 'AdminDrivers',

  middleware: [
    'auth',
    'lo'
  ],

  mixins: [
    MISC,
    FORMATTER
  ],

  metaInfo: () => ({
    title: 'Drivers'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      driver: {
        id: null,
        driver_name: null,
        mobile_number: null,
        active: 1
      },
      tableDrivers: {
        busy: false,
        filter: {
          search: null
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 6, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'driver_name' },
          { mobile: 3, key: 'mobile_number' },
          { mobile: 4, key: 'active', class: 'text-center', formatter: this.yesOrNo },
          { mobile: 5, key: 'updated_at', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    ModalDriverTitle () {
      return this.state.editing ? 'Edit Driver' : 'Add Driver'
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableDrivers.busy = true
      return await SLODriver.get({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search
      }).then(({ data }) => {
        this.tableDrivers.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        this.tableDrivers.totalRows = 0
        return []
      }).finally(() => {
        this.tableDrivers.busy = false
      })
    },

    createDriver () {
      this.state.editing = false
      this.driver.id = null
      this.driver.driver_name = null
      this.driver.mobile_number = null
      this.driver.active = 1
      this.$bvModal.show('modalDriver')
    },

    editDriver (driver) {
      this.state.editing = true
      this.driver.id = driver.id
      this.driver.driver_name = driver.driver_name
      this.driver.mobile_number = driver.mobile_number
      this.driver.active = driver.active
      this.$bvModal.show('modalDriver')
    },

    async validateDriver () {
      await this.$refs.formDriver.validate().then(
        async validDriver => {
          if (!validDriver) {
            return this.swalInvalid()
          }

          this.swalConfirm({
            html: () => {
              if (this.state.editing) {
                return '<h6>Save your changes on this Driver?</h6>'
              }
              return '<h6>Create this Driver?</h6>'
            },
            preConfirm: () => {
              this.state.busy = true
              if (this.state.editing) {
                return this.updateDriver()
              }
              return this.submitDriver()
            }
          })
        }
      )
    },

    async submitDriver () {
      return await SLODriver.post(this.driver).then(
        ({ data: { message } }) => {
          this.$bvModal.hide('modalDriver')
          this.swalSuccess(message).then(() => {
            this.$refs.tableDrivers.refresh()
          })
        }
      ).catch(({ message }) => {
        this.$refs.formDriver.setErrors(message)
      }).finally(() => {
        this.state.busy = false
      })
    },

    async updateDriver () {
      return await SLODriver.put(this.driver).then(
        ({ data: { message, driver } }) => {
          this.$bvModal.hide('modalDriver')
          this.swalSuccess(message).then(() => {
            const tableDriversRow = QUERY.find(this.$refs.tableDrivers.localItems, {
              id: Number(driver.id)
            })
            if (tableDriversRow) {
              tableDriversRow.driver_name = driver.driver_name
              tableDriversRow.mobile_number = driver.mobile_number
              tableDriversRow.active = driver.active
              tableDriversRow.updated_at = driver.updated_at
            }
          })
        }
      ).catch(({ message }) => {
        this.$refs.formDriver.setErrors(message)
      }).finally(() => {
        this.state.busy = false
      })
    }
  }
}
</script>
